import { commonTheme, json } from './common.theme'
import {
  Colors,
  Theme,
  TransactionDetailsPage,
  Sidebar,
  SidebarPopup,
  Scrollbar,
  Effects,
  TopMenu,
  SignicatLogo,
  LoginPage,
  TextInput,
  Button,
  LoadingSpinner,
  TransactionListPage,
  SelectInput,
} from './theme'

const colors: Colors = {
  backgroundColor: '#ffffff',
  primary: '#41FFD1',
  secondary: '#2A0062',
  tertiary: '#565B60',
  quaternary: '#2B253A',
  quinary: '#2A0062',
  senary: '#9B91B0',
}

const effects: Effects = {
  hover: {
    brightness: 1.2,
  },
}

const scrollbar: Scrollbar = {
  thumb: '#00000080',
  track: 'transparent',
}

const loadingSpinner: LoadingSpinner = {
  bgColor: '',
  primary: {
    color: '#5F36BC',
    bgColor: '#F1F3F7',
  },
  secondary: {
    color: '#26D7AC',
    bgColor: '##F1F3F7',
  },
}

const textInput: TextInput = {
  color: '#25292C',
  iconColor: '#25292C',
  labelColor: '#2B253A',
  bgColor: '#FFF',
  border: '1px solid #B7BDC6',
  hover: {
    border: '1px solid #666D78',
  },
  focus: {
    border: '1px solid #794CDE',
    boxShadow: '0px 0px 0px 3px #C4AAFF',
  },
  error: {
    border: '1px solid #D83535',
    boxShadow: '0px 0px 0px 3px #FFB0B0',
  },
  disabled: {
    color: '#7F858B',
    bgColor: '#F6F6F6',
    border: '1px solid #DCDEE0',
  },
  readOnly: {
    bgColor: '#F6F6F6',
    border: '1px solid #F6F6F6',
  },
}

const selectInput: SelectInput = {
  color: '#25292C',
  labelColor: '#2B253A',
  bgColor: '#FFF',
  border: '1px solid #B7BDC6',
  hover: {
    border: '1px solid #666D78',
  },
  focus: {
    border: '1px solid #794CDE',
    boxShadow: '0px 0px 0px 3px #C4AAFF',
  },
  error: {
    border: '1px solid #D83535',
    boxShadow: '0px 0px 0px 3px #FFB0B0',
  },
  disabled: {
    color: '#7F858B',
    bgColor: '#F6F6F6',
    border: '1px solid #DCDEE0',
  },
  readOnly: {
    bgColor: '#F6F6F6',
    border: '1px solid #F6F6F6',
  },
  menu: {
    border: '1px solid #B7BDC6',
    color: '#25292C',
    colorDisabled: '#ACB0B5',
    bgColor: '#fff',
    option: {
      colorSelected: '#fff',
      bgColorSelected: '#794CDE',
      colorHover: '#25292C',
      bgColorHover: '#f1f3f7',
      borderFocus: '2px solid #AC88FF',
    },
  },
  tags: {
    include: {
      color: '#37187e',
      bgColor: '#f8f5ff',
      border: '1px solid #f1eaff',
    },
    exclude: {
      color: '#B62424',
      bgColor: '#FFF0F0',
      border: '1px solid #FFE2E2',
    },
  },
}

const sidebar: Sidebar = {
  border: '1px solid #e9e7ee',
  bgColor: '#FFF',
  toggle: {
    color: '#F6F6F6',
    bgColor: '#5F36BC',
  },
  menuItem: {
    color: '#230B58',
    colorHover: '#5F36BC',
    bgColorHover: '#F1F3F7',
  },
}

const sidebarPopup: SidebarPopup = {
  overlay: {
    bgColor: 'rgba(19, 22, 26, 0.50)',
  },
  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)',
  bgColor: '#FFF',
  closeIcon: {
    color: '#6C7176',
  },
  header: {
    color: '#2B253A',
  },
}

const signicatLogo: SignicatLogo = {
  color: '#2A0062',
  backgroundColor: '#FFFFFF',
}

const topMenu: TopMenu = {
  color: '#FFFFFF',
  backgroundColor: '#2a0062',
  search: {
    color: '#F6F6F6',
    backgroundColor: '#2a0062',
    border: '1px solid #794CDE',
    iconColor: '#F6F6F6',
    buttonColor: '#FFFFFF',
    buttonBgColor: '#5F36BC',
  },
  notifications: {
    iconColor: '#FFFFFF',
    dotBgColor: '#41FFD1',
    dotColor: '#2A0062',
  },
  userInfo: {
    color: '#FFFFFF',
    iconColor: '#FFFFFF',
    iconBgColor: '#5f36bc',
  },
  tenantSwitcher: {
    color: '#FFFFFF',
    backgroundColor: '#2a0062',
    border: '1px solid #794CDE',
    secondaryColor: '#DCCDFF',
    dropdownIconColor: '#FFFFFF',
    menu: {
      border: '1px solid #794CDE',
      color: '#FFFFFF',
      colorDisabled: '#ACB0B5',
      bgColor: '#2a0062',
      option: {
        colorSelected: '#fff',
        bgColorSelected: '#794CDE',
        colorHover: '#25292C',
        bgColorHover: '#f1f3f7',
        borderFocus: '2px solid #AC88FF',
      },
    },
  },
}

const button: Button = {
  primary: {
    color: '#FFFFFF',
    bgColor: '#5F36BC',
    border: '1px solid #5F36BC',
    hover: {
      bgColor: '#794CDE',
      border: '1px solid #794CDE',
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
    },
    active: {
      bgColor: '#48249A',
      border: '1px solid #48249A',
      boxShadow: '0px 0px 0px 3px #C4AAFF',
    },
    disabled: {
      color: '#F6F6F6',
      bgColor: '#ACB0B5',
      border: '1px solid #ACB0B5',
    },
  },
  secondary: {
    color: '#2A0062',
    bgColor: '#FFF',
    border: '2px solid #CECBD6',
    hover: {
      bgColor: '#F1F3F7',
      border: '2px solid #CECBD6',
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
    },
    active: {
      bgColor: '#E8EBF0',
      border: '2px solid #CECBD6',
      boxShadow: '0px 0px 0px 3px #C4AAFF',
    },
    disabled: {
      color: '#7F858B',
      bgColor: '#F6F6F6',
      border: '2px solid #F6F6F6',
    },
  },
}

const loginPage: LoginPage = {
  bgColor: '#F7F9FC',
  header: {
    primaryColor: '#2B253A',
    secondaryColor: '#37187E',
  },
  text: {
    primaryColor: '#2B253A',
    secondaryColor: '#565B60',
  },
  logo: {
    bgColor: '#2A0062',
    color: '#FFF',
  },
  dot: {
    color: '#41FFD1',
  },
}

const transactionDetailsPage: TransactionDetailsPage = {
  backButton: {
    color: '#25292C',
  },
  title: {
    primaryColor: '#2B253A',
    secondaryColor: '#565B60',
    iconButton: {
      border: '1px solid #CECBD6',
      color: '#5F36BC',
      bgColor: '#FFFFFF',
    },
  },
  searchBar: {
    border: '1px solid #B7BDC6',
    color: '#91969B',
    iconColor: '#6C7176',
  },
  valueButton: {
    color: '#565B60',
    bgColor: '#FFFFFF',
    border: '1px solid #CECBD6',
    iconColor: '#794CDE',
    bgColorHover: '',
    borderHover: '',
    colorHover: '',
    iconColorHover: '',
  },
  tag: {
    color: '#2A0062',
    bgColor: '#F7F9FC',
    iconColor: '#2A0062',
    border: '1px solid #E9E7EE',
  },
  dataGridItem: {
    titleColor: '#190147',
    iconColor: '#794CDE',
    treeLineColor: '#DCCDFF',
    treeLineIconColor: '#DCCDFF',
    propertyNameColor: '#7F858B',
    propertyValueColor: '#2B253A',
    propertyIconColor: '#5F36BC',
    expandIconColor: '#303438',
    border: '1px solid #E6DDF9',
    boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#FFFFFF',
    separatorLineColor: '#E9E7EE',
  },
  pillStatusResult: {
    green: {
      text: '#2B633F',
      dot: '#2c9c77',
      bg: '#E0FCF5',
    },
    red: {
      text: '#A82626',
      dot: '#D8513F',
      bg: '#FFE9EB',
    },
    yellow: {
      text: '#995403',
      dot: '#FB9600',
      bg: '#FFF0E2',
    },
    blue: {
      text: '#152F8A',
      dot: '#3D5CCA',
      bg: '#ECEFFB',
    },
    default: {
      text: '#767676',
      dot: '#DFDBD6',
      bg: '#F5F4F3',
    },
  },
}

const transactionListPage: TransactionListPage = {
  title: {
    color: '#565B60',
    iconColor: '#2A0062',
    refreshButton: {
      color: '#5F36BC',
      bgColor: '#FFFFFF',
      border: '1px solid #CECBD6',
    },
  },
  filters: {
    labelColor: '#2B253A',
    inputColor: '#25292C',
    inputBgColor: 'transparent',
    inputBorder: '1px solid #B7BDC6',
    resetButton: {
      color: '#5F36BC',
      bgColor: '#FFFFFF',
      border: '1px solid #CECBD6',
    },
  },
  pagination: {
    selectInput: {
      color: '#25292C',
      bgColor: '#f1f3f7',
      border: '0px',
    },
    iconButton: {
      border: '0',
      color: '#2A0062',
      bgColor: '#F1F3F7',
    },
  },
}

const table = {
  border: '1px solid #E9E7EE',
  header: {
    color: '#37187E',
    colorSecondary: '#565B60',
    bgColor: '#ffffff',
  },
  row: {
    color: '#25292C',
    bgColorHover: '#F7F9FC',
    linkColor: '#2A0062',
  },
  columnVisibilityManager: {
    color: '#25292C',
    titleColor: '#2B253A',
    bgColor: '#FFFFFF',
    border: '1px solid #E9E7EE',
    openButton: {
      color: '#37187E',
      bgColor: '#F7F9FC',
      border: 'none',
      iconColor: '#2A0062',
    },
  },
}

const checkbox = {
  label: {
    color: '#25292C',
    colorDisabled: '#7F858B',
  },
  checked: {
    backgroundColor: '#5F36BC',
    color: '#FFFFFF',
    disabled: {
      backgroundColor: '#C4C7CA',
      color: '#FFFFFF',
    },
    focused: {
      outline: '2px solid #c4aaff',
    },
    hover: {
      backgroundColor: '#794CDE',
      color: '#FFFFFF',
    },
  },
  unchecked: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #4F555F',
    disabled: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #C4C7CA',
    },
    focused: {
      outline: '2px solid #c4aaff',
    },
    hover: { border: '1px solid #2A0062' },
  },
}

const dateInput = {
  input: {
    color: '#25292c',
    border: '1px solid #b7bdc6',
    bgColor: '#FFFFFF',
    popoverButtonColor: '#303438',
  },
  popover: {
    backgroundColor: '#ffffff',
    border: '1px solid #E9E7EE',
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.10)',
    headerColor: '#2A0062',
    headerButton: {
      color: '#5F36BC',
      colorHover: '#2A0062',
      bgColorHover: '#F1F3F7',
    },
    tableCell: {
      headerColor: '#2A0062',
      color: '#2A0062',
      colorHover: '#7F858B',
      bgColorHover: '#F1F3F7',
      colorOutsideMonth: '#7F858B',
      colorDataSelected: '#FFFFFF',
      bgColorDataSelected: '#5F36BC',
    },
  },
}

const phoneInput = {
  labelColor: '#2B253A',
  border: '1px solid #B7BDC6',
  color: '#25292C',
  backgroundColor: '#FFF',
  colorSecondary: '#565B60',
  iconColor: '#6C7176',
  dropdownBoxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.1)',

  disabled: {
    labelColor: '#7F858B',
    border: '1px solid #DCDEE0',
    color: '#7F858B',
    backgroundColor: '#F6F6F6',
    iconColor: '#91969B',
  },

  error: {
    border: '1px solid #D83535',
    boxShadow: '0px 0px 0px 3px #FFB0B0',
    color: '#941616',
    iconColor: '#D83535',
  },

  focus: {
    border: '1px solid #794CDE',
    boxShadow: '0px 0px 0px 3px #C4AAFF',
  },

  hover: {
    border: '1px solid #666D78',
    itemBgColor: '#F1F3F7',
    prefixButtonBackgroundColor: '#E8EBF0',
  },
}

export const lightTheme: Theme = {
  ...commonTheme,
  json,
  colors,
  effects,
  loadingSpinner,
  scrollbar,
  textInput,
  selectInput,
  popup: {
    wrapBgColor: '#FFFFFF',
    border: '1px solid var(--Stroke-primary, #E9E7EE)',
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.10)',
    bgColor: '#FFFFFF',
    color: '#2B253A',
    tenantSwitcher: {
      color: '#25292C',
      backgroundColor: '#FFF',
      border: '1px solid #B7BDC6',
      secondaryColor: '#DCCDFF',
      dropdownIconColor: '#303438',
      menu: {
        border: '1px solid #B7BDC6',
        color: '#25292C',
        colorDisabled: '#ACB0B5',
        bgColor: '#fff',
        option: {
          colorSelected: '#fff',
          bgColorSelected: '#794CDE',
          colorHover: '#25292C',
          bgColorHover: '#f1f3f7',
          borderFocus: '2px solid #AC88FF',
        },
      },
    },
  },
  sidebar,
  sidebarPopup,
  signicatLogo,
  topMenu,
  button,
  loginPage,
  transactionDetailsPage,
  transactionListPage,
  table,
  checkbox,
  dateInput,
  phoneInput,
}
