import { FC, useState } from 'react'
import {
  Container,
  PopoverGlobalStyle,
  TimePrimaryButton,
  TimeSecondaryButton,
} from './DatePicker.styles'

import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DatePicker,
  DatePickerProps,
  DateSegment,
  DateValue,
  Dialog,
  Group,
  Heading,
  Label,
  Popover,
  TimeField,
} from 'react-aria-components'
import { parseDate, Time } from '@internationalized/date'

interface DatePickerInputProps extends Omit<DatePickerProps<DateValue>, 'onChange' | 'value'> {
  value?: number
  onChange?: (value: number) => void
  // name?: string
  isTimeIncluded?: boolean
}

export const DatePickerInput: FC<DatePickerInputProps> = (props) => {
  const convertUnixToDateValue = (unixTimestamp: number): DateValue | undefined => {
    if (!unixTimestamp) return undefined

    // convert to dd-mm-yyyy date format
    const date = new Date(unixTimestamp * 1000).toISOString().slice(0, 10)
    return parseDate(date)
  }

  const [date, setDate] = useState<DateValue | undefined>(convertUnixToDateValue(props.value))
  const [hour, setHour] = useState(0)
  const [minute, setMinute] = useState(0)

  const onChangeWrapper = (value: DateValue) => {
    setDate(value)

    // convert to unix timestamp
    const unixTimestamp = Math.floor(
      new Date(value.year, value.month, value.day, hour, minute).getTime() / 1000
    )

    // return unixTimestamp
    props.onChange(unixTimestamp)
  }

  return (
    <Container>
      <PopoverGlobalStyle />
      <DatePicker
        aria-label="Pick date"
        {...props}
        onChange={onChangeWrapper}
        value={convertUnixToDateValue(props.value)}
      >
        <Group>
          <DateInput aria-label="Pick date">
            {(segment) => (
              <DateSegment segment={segment} children={`${segment.text.replace('.', '/')}`} />
            )}
          </DateInput>
          <Button>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M2.375 6.24996C2.375 4.57009 3.7368 3.20829 5.41667 3.20829H14.5833C16.2632 3.20829 17.625 4.57009 17.625 6.24996V15.4166C17.625 17.0965 16.2632 18.4583 14.5833 18.4583H5.41667C3.7368 18.4583 2.375 17.0965 2.375 15.4166V6.24996ZM5.41667 4.29163C4.33511 4.29163 3.45833 5.1684 3.45833 6.24996V15.4166C3.45833 16.4982 4.33511 17.375 5.41667 17.375H14.5833C15.6649 17.375 16.5417 16.4982 16.5417 15.4166V6.24996C16.5417 5.1684 15.6649 4.29163 14.5833 4.29163H5.41667Z"
                fill="currentColor"
              />
              <path
                d="M2.375 7.80554C2.375 7.50639 2.61751 7.26387 2.91667 7.26387L17.0833 7.26387C17.3825 7.26387 17.625 7.50639 17.625 7.80554C17.625 8.10469 17.3825 8.34721 17.0833 8.34721L2.91667 8.34721C2.61751 8.34721 2.375 8.10469 2.375 7.80554Z"
                fill="currentColor"
              />
              <path
                d="M7.07194 1.54163C7.37109 1.54163 7.61361 1.78414 7.61361 2.08329L7.61361 5.09607C7.61361 5.39522 7.37109 5.63774 7.07194 5.63774C6.77279 5.63774 6.53027 5.39522 6.53027 5.09607V2.08329C6.53027 1.78414 6.77279 1.54163 7.07194 1.54163Z"
                fill="currentColor"
              />
              <path
                d="M12.8333 1.54163C13.1324 1.54163 13.3749 1.78414 13.3749 2.08329V5.09607C13.3749 5.39522 13.1324 5.63774 12.8333 5.63774C12.5341 5.63774 12.2916 5.39522 12.2916 5.09607V2.08329C12.2916 1.78414 12.5341 1.54163 12.8333 1.54163Z"
                fill="currentColor"
              />
              <path
                d="M6.125 11.1111C6.125 10.812 6.36751 10.5695 6.66667 10.5695C6.96582 10.5695 7.20842 10.812 7.20842 11.1111C7.20842 11.4103 6.9659 11.6528 6.66675 11.6528C6.3676 11.6528 6.125 11.4103 6.125 11.1111Z"
                fill="currentColor"
              />
              <path
                d="M9.43066 11.1111C9.43066 10.8119 9.67318 10.5694 9.97233 10.5694C10.2715 10.5694 10.5141 10.8119 10.5141 11.1111C10.5141 11.4103 10.2716 11.6528 9.97241 11.6528C9.67326 11.6528 9.43066 11.4103 9.43066 11.1111Z"
                fill="currentColor"
              />
              <path
                d="M12.7363 11.1111C12.7363 10.8119 12.9788 10.5694 13.2779 10.5694C13.5771 10.5694 13.8197 10.8119 13.8197 11.1111C13.8197 11.4103 13.5772 11.6528 13.278 11.6528C12.9789 11.6528 12.7363 11.4103 12.7363 11.1111Z"
                fill="currentColor"
              />
              <path
                d="M12.7363 13.9444C12.7363 13.6453 12.9788 13.4028 13.2779 13.4028C13.5771 13.4028 13.8197 13.6453 13.8197 13.9444C13.8197 14.2436 13.5772 14.4861 13.278 14.4861C12.9789 14.4861 12.7363 14.2436 12.7363 13.9444Z"
                fill="currentColor"
              />
              <path
                d="M9.43066 13.9444C9.43066 13.6453 9.67318 13.4028 9.97233 13.4028C10.2715 13.4028 10.5141 13.6453 10.5141 13.9444C10.5141 14.2436 10.2716 14.4861 9.97241 14.4861C9.67326 14.4861 9.43066 14.2436 9.43066 13.9444Z"
                fill="currentColor"
              />
              <path
                d="M6.12505 13.9444C6.12505 13.6453 6.36756 13.4028 6.66672 13.4028C6.96587 13.4028 7.20847 13.6453 7.20847 13.9444C7.20847 14.2436 6.96595 14.4861 6.6668 14.4861C6.36765 14.4861 6.12505 14.2436 6.12505 13.9444Z"
                fill="currentColor"
              />
            </svg>
          </Button>
        </Group>
        <Popover className={props.isTimeIncluded && 'react-aria-Popover time-input-included'}>
          <Dialog>
            <Calendar>
              <header>
                <Button slot="previous">
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path
                      d="M15.4598 17.4596C15.2059 17.7135 14.7944 17.7135 14.5405 17.4596L9.54053 12.4596C9.28669 12.2058 9.28669 11.7942 9.54053 11.5404L14.5405 6.54041C14.7944 6.28656 15.2059 6.28656 15.4598 6.54041C15.7136 6.79425 15.7136 7.2058 15.4598 7.45964L10.9194 12L15.4598 16.5404C15.7136 16.7942 15.7136 17.2058 15.4598 17.4596Z"
                      fill="currentColor"
                    />
                  </svg>
                </Button>
                <Heading />
                <Button slot="next">
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path
                      d="M9.54048 6.54036C9.79432 6.28652 10.2059 6.28652 10.4597 6.54036L15.4597 11.5404C15.7136 11.7942 15.7136 12.2058 15.4597 12.4596L10.4597 17.4596C10.2059 17.7134 9.79432 17.7134 9.54048 17.4596C9.28664 17.2058 9.28664 16.7942 9.54048 16.5404L14.0809 12L9.54048 7.45959C9.28664 7.20575 9.28664 6.7942 9.54048 6.54036Z"
                      fill="currentColor"
                    />
                  </svg>
                </Button>
              </header>
              <CalendarGrid>{(date) => <CalendarCell date={date} />}</CalendarGrid>

              {props?.isTimeIncluded && (
                <>
                  <TimeField
                    aria-label="Pick time"
                    hourCycle={24}
                    onChange={(e) => {
                      setHour(e.hour)
                      setMinute(e.minute)
                      onChangeWrapper(date)
                    }}
                    value={new Time(hour, minute)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.99175 1.66669C5.39175 1.66669 1.66675 5.40002 1.66675 10C1.66675 14.6 5.39175 18.3334 9.99175 18.3334C14.6001 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6001 1.66669 9.99175 1.66669ZM10.0001 16.6667C6.31675 16.6667 3.33341 13.6834 3.33341 10C3.33341 6.31669 6.31675 3.33335 10.0001 3.33335C13.6834 3.33335 16.6667 6.31669 16.6667 10C16.6667 13.6834 13.6834 16.6667 10.0001 16.6667ZM10.4167 5.83335H9.16675V10.8334L13.5417 13.4584L14.1667 12.4334L10.4167 10.2084V5.83335Z"
                        fill="currentColor"
                      />
                    </svg>
                    <DateInput>{(segment) => <DateSegment segment={segment} />}</DateInput>
                  </TimeField>

                  <TimePrimaryButton>Submit</TimePrimaryButton>
                  <TimeSecondaryButton>Clear</TimeSecondaryButton>
                </>
              )}
            </Calendar>
          </Dialog>
        </Popover>
      </DatePicker>
    </Container>
  )
}
