import { styled, createGlobalStyle } from 'styled-components'

export const PopoverGlobalStyle = createGlobalStyle`
  .react-aria-Popover[data-trigger=DatePicker] {
    width: 320px;
    height: 312px;
    background: ${({ theme }) => theme.dateInput.popover.backgroundColor};
    border: ${({ theme }) => theme.dateInput.popover.border};
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.10);
    border-radius: 4px;
    padding: 12px;

    &.time-input-included{
      height: 454px;
    }

    .react-aria-TimeField{
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 5px 12px;
      border: ${({ theme }) => theme.dateInput.input.border};
      border-radius: 4px;

      svg{
        color: ${({ theme }) => theme.dateInput.input.popoverButtonColor};
      }

      .react-aria-DateInput{
        display: flex;
        color: ${({ theme }) => theme.dateInput.input.color};
      }
    }
  }

  .react-aria-Calendar{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    header{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${({ theme }) => theme.dateInput.popover.headerColor};

      h2{
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.16px;
      }

      button{
        width: 40px;
        height: 40px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        color:${({ theme }) => theme.dateInput.popover.headerButton.color};

        &:hover{
          border-radius:  8px;
          background:  ${({ theme }) => theme.dateInput.popover.headerButton.bgColorHover};
          color:  ${({ theme }) => theme.dateInput.popover.headerButton.colorHover};
        }
      }
    }

    table{
      border-collapse: collapse;
      width: 280px;
      height: 240px;

      td, th{
        width: 40px;
        height: 40px;
      }


      .react-aria-CalendarHeaderCell{
        color:  ${({ theme }) => theme.dateInput.popover.tableCell.headerColor};
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
      }

      .react-aria-CalendarCell{
        width: 40px;
        height: 40px;
        color:  ${({ theme }) => theme.dateInput.popover.tableCell.color};
        text-align: center;
        font-family: Inter;
        font-size:  14px;
        font-style: normal;
        font-weight: 400;
        line-height:  20px;

        display: flex;
        justify-content: center;
        align-items: center;

        outline: none !important;
        cursor: pointer;

        &:not([data-selected="true"]):hover{
          border-radius:  8px;
          background:  ${({ theme }) => theme.dateInput.popover.tableCell.bgColorHover};
          color:  ${({ theme }) => theme.dateInput.popover.tableCell.colorHover};
        }

        &[data-outside-month="true"]{
          color:  ${({ theme }) => theme.dateInput.popover.tableCell.colorOutsideMonth};
          text-align: center;
          font-family: Inter;
          font-size:  14px;
          font-style: normal;
          font-weight: 400;
          line-height:  20px;
        }

        &[data-selected="true"]{
          border-radius:  8px;
          background:  ${({ theme }) => theme.dateInput.popover.tableCell.bgColorDataSelected};
          color:  ${({ theme }) => theme.dateInput.popover.tableCell.colorDataSelected};
          font-weight: 600;
          line-height:  20px;
          cursor: default;
        }


      }
    }
  }
`

export const Container = styled.div`
  width: 100%;
  height: 40px;

  .react-aria-DatePicker {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: ${({ theme }) => theme.dateInput.input.border};
    background-color: ${({ theme }) => theme.dateInput.input.bgColor};

    .react-aria-Group {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;

      & > button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        color: ${({ theme }) => theme.dateInput.input.popoverButtonColor};
      }
    }

    .react-aria-Button {
      border: none;
      forced-color-adjust: none;
      border-radius: 4px;
      border: none;
      margin-left: -1.929rem;
      width: 1.429rem;
      height: 1.429rem;
      padding: 0;

      &[data-pressed] {
        box-shadow: none;
      }

      &[data-focus-visible] {
        outline-offset: 2px;
      }
    }

    .react-aria-DateInput {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 10px 16px;

      color: ${({ theme }) => theme.dateInput.input.color};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .react-aria-Popover[data-trigger='DatePicker'] {
    max-width: unset;
  }
`

export const TimePrimaryButton = styled.button`
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  background: ${({ theme }) => theme.dateInput.popover.tableCell.bgColorDataSelected};
  color: ${({ theme }) => theme.dateInput.popover.tableCell.colorDataSelected};
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
`

export const TimeSecondaryButton = styled.button`
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  background: transparent;
  color: ${({ theme }) => theme.dateInput.popover.tableCell.bgColorDataSelected};
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
`
