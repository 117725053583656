import {
  Colors,
  Theme,
  TransactionDetailsPage,
  Sidebar,
  SidebarPopup,
  Scrollbar,
  Effects,
  TopMenu,
  SignicatLogo,
  LoginPage,
  TextInput,
  Button,
  LoadingSpinner,
  TransactionListPage,
  SelectInput,
} from './theme'

import { commonTheme, json } from './common.theme'

const colors: Colors = {
  backgroundColor: '#13161a',
  primary: '#5F36BC',
  secondary: '#DCCDFF',
  tertiary: '#C4C7CA',
  quaternary: '#FBFAFF',
  quinary: '#9D98A9',
  senary: '#7F7B87',
}

const effects: Effects = {
  hover: {
    brightness: 0.8,
  },
}

const scrollbar: Scrollbar = {
  thumb: '#C4C7CA80',
  track: 'transparent',
}

const loadingSpinner: LoadingSpinner = {
  bgColor: '',
  primary: {
    color: '#AC88FF',
    bgColor: '#363B44',
  },
  secondary: {
    color: '#26D7AC',
    bgColor: '#363B44',
  },
}

const textInput: TextInput = {
  color: '#F6F6F6',
  iconColor: '#F6F6F6',
  labelColor: '#FBFAFF',
  bgColor: '#13161A',
  border: '1px solid #4F555F',
  hover: {
    border: '1px solid #818892',
  },
  focus: {
    border: '1px solid #AC88FF',
    boxShadow: '0px 0px 0px 3px #48249A',
  },
  error: {
    border: '1px solid #FA4949',
    boxShadow: '0px 0px 0px 3px #710C0C',
  },
  disabled: {
    color: '#ACB0B5',
    bgColor: '#303438',
    border: '1px solid #6C7176',
  },
  readOnly: {
    bgColor: '#303438',
    border: '1px solid #303438',
  },
}

const selectInput: SelectInput = {
  color: '#F6F6F6',
  labelColor: '#FBFAFF',
  bgColor: '#13161A',
  border: '1px solid #4F555F',
  hover: {
    border: '1px solid #818892',
  },
  focus: {
    border: '1px solid #AC88FF',
    boxShadow: '0px 0px 0px 3px #48249A',
  },
  error: {
    border: '1px solid #FA4949',
    boxShadow: '0px 0px 0px 3px #710C0C',
  },
  disabled: {
    color: '#ACB0B5',
    bgColor: '#303438',
    border: '1px solid #6C7176',
  },
  readOnly: {
    bgColor: '#303438',
    border: '1px solid #303438',
  },
  menu: {
    border: '1px solid #4F555F',
    color: '#F6F6F6',
    colorDisabled: '#7F858B',
    bgColor: '#13161A',
    option: {
      colorSelected: '#fff',
      bgColorSelected: '#48249A',
      colorHover: '#13161A',
      bgColorHover: '#f1f3f7',
      borderFocus: '2px solid #794CDE',
    },
  },
  tags: {
    include: {
      color: '#37187e',
      bgColor: '#f8f5ff',
      border: '1px solid #f1eaff',
    },
    exclude: {
      color: '#B62424',
      bgColor: '#FFF0F0',
      border: '1px solid #FFE2E2',
    },
  },
}

const sidebar: Sidebar = {
  border: '1px solid #585168',
  bgColor: '#13161A',
  toggle: {
    color: '#C4C7CA',
    bgColor: '#794CDE',
  },
  menuItem: {
    color: '#9D98A9',
    colorHover: '#C4AAFF',
    bgColorHover: '#363B44',
  },
}

const sidebarPopup: SidebarPopup = {
  overlay: {
    bgColor: 'rgba(54, 59, 68, 0.3)',
  },
  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)',
  bgColor: '#13161A',
  closeIcon: {
    color: '#ACB0B5',
  },
  header: {
    color: '#FBFAFF',
  },
}

const signicatLogo: SignicatLogo = {
  color: '#2A0062',
  backgroundColor: '#FFFFFF',
}

const topMenu: TopMenu = {
  color: '#F9F9FF',
  backgroundColor: '#2b253a',
  search: {
    color: '#C4C7CA',
    backgroundColor: '#2b253a',
    border: '1px solid #AC88FF',
    iconColor: '#C4C7CA',
    buttonColor: '#F8F5FF',
    buttonBgColor: '#AC88FF',
  },
  notifications: {
    iconColor: '#F8F5FF',
    dotBgColor: '#5F36BC',
    dotColor: '#F8F5FF',
  },
  userInfo: {
    color: '#F9F9FF',
    iconColor: '#F8F5FF',
    iconBgColor: '#5f36bc',
  },
  tenantSwitcher: {
    color: '#F8F5FF',
    backgroundColor: '#2b253a',
    border: '1px solid #AC88FF',
    secondaryColor: '#DCCDFF',
    dropdownIconColor: '#F8F5FF',
    menu: {
      border: '1px solid #AC88FF',
      color: '#F8F5FF',
      colorDisabled: '#7F858B',
      bgColor: '#2b253a',
      option: {
        colorSelected: '#fff',
        bgColorSelected: '#48249A',
        colorHover: '#13161A',
        bgColorHover: '#f1f3f7',
        borderFocus: '2px solid #794CDE',
      },
    },
  },
}

const button: Button = {
  primary: {
    color: '#FFFFFF',
    bgColor: '#794CDE',
    border: '1px solid #794CDE',
    hover: {
      bgColor: '#5F36BC',
      border: '1px solid #5F36BC',
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
    },
    active: {
      bgColor: '#9465FF',
      border: '1px solid #9465FF',
      boxShadow: '0px 0px 0px 3px #48249A',
    },
    disabled: {
      color: '#C4C7CA',
      bgColor: '#565B60',
      border: '1px solid #565B60',
    },
  },
  secondary: {
    color: '#F8F5FF',
    bgColor: '#25292C',
    border: '2px solid #6E677C',
    hover: {
      bgColor: '#1D2127',
      border: '2px solid #6E677C',
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
    },
    active: {
      bgColor: '#363B44',
      border: '2px solid #6E677C',
      boxShadow: '0px 0px 0px 3px #48249A',
    },
    disabled: {
      color: '#ACB0B5',
      bgColor: '#303438',
      border: '2px solid #303438',
    },
  },
}

const loginPage: LoginPage = {
  bgColor: '#1D2127',
  header: {
    primaryColor: '#FBFAFF',
    secondaryColor: '#DCCDFF',
  },
  text: {
    primaryColor: '#FBFAFF',
    secondaryColor: '#C4C7CA',
  },
  logo: {
    bgColor: '#2A0062',
    color: '#FFF',
  },
  dot: {
    color: '#5F36BC',
  },
}

const transactionDetailsPage: TransactionDetailsPage = {
  backButton: {
    color: '#F6F6F6',
  },
  title: {
    primaryColor: '#FBFAFF',
    secondaryColor: '#C4C7CA',
    iconButton: {
      border: '1px solid #6E677C',
      color: '#C4AAFF',
      bgColor: '#25292C',
    },
  },
  searchBar: {
    border: '1px solid #4F555F',
    color: '#ACB0B5',
    iconColor: '#ACB0B5',
  },
  valueButton: {
    color: '#C4C7CA',
    bgColor: '#25292C',
    border: '1px solid #6E677C',
    iconColor: '#DCCDFF',
    bgColorHover: '',
    borderHover: '',
    colorHover: '',
    iconColorHover: '',
  },
  tag: {
    color: '#F8F5FF',
    bgColor: '#1D2127',
    iconColor: '#DCCDFF',
    border: '1px solid #585168',
  },
  dataGridItem: {
    titleColor: '#DCCDFF',
    iconColor: '#9D98A9',
    treeLineColor: '#9D98A9',
    treeLineIconColor: '#9D98A9',
    propertyNameColor: '#ACB0B5',
    propertyValueColor: '#FBFAFF',
    propertyIconColor: '#9D98A9',
    expandIconColor: '#DCDEE0',
    border: '1px solid #585168',
    boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#13161a',
    separatorLineColor: '#585168',
  },
  pillStatusResult: {
    green: {
      text: '#E4FFF8',
      dot: '#26D7AC',
      bg: '#01664D',
    },
    red: {
      text: '#FFE2E2',
      dot: '#FF7070',
      bg: '#941616',
    },
    yellow: {
      text: '#FFF5E1',
      dot: '#FFB35A',
      bg: '#954003',
    },
    blue: {
      text: '#F0F0FF',
      dot: '#9C9CFF',
      bg: '#3131D9',
    },
    default: {
      text: '#EDEEEF',
      dot: '#ACB0B5',
      bg: '#565B60',
    },
  },
}

const transactionListPage: TransactionListPage = {
  title: {
    color: '#C4C7CA',
    iconColor: '#9D98A9',
    refreshButton: {
      color: '#C4AAFF',
      bgColor: '#25292C',
      border: '1px solid #6E677C',
    },
  },
  filters: {
    labelColor: '#FBFAFF',
    inputColor: '#F6F6F6',
    inputBgColor: 'transparent',
    inputBorder: '1px solid #4F555F',
    resetButton: {
      color: '#C4AAFF',
      bgColor: '#25292C',
      border: '1px solid #6E677C',
    },
  },
  pagination: {
    selectInput: {
      color: '#F6F6F6',
      bgColor: '#1D2127',
      border: '0px',
    },
    iconButton: {
      border: '0',
      color: '#F8F5FF',
      bgColor: '#1D2127',
    },
  },
}

const table = {
  border: '1px solid #585168',
  header: {
    color: '#DCCDFF',
    colorSecondary: '#C4C7CA',
    bgColor: '#13161a',
  },
  row: {
    color: '#F6F6F6',
    bgColorHover: '#DCCDFF',
    linkColor: '#F8F5FF',
  },
  columnVisibilityManager: {
    color: '#F6F6F6',
    titleColor: '#FBFAFF',
    bgColor: '#13161a',
    border: '1px solid #585168',
    openButton: {
      color: '#DCCDFF',
      bgColor: '#1D2127',
      border: 'none',
      iconColor: '#DCCDFF',
    },
  },
}

const checkbox = {
  label: {
    color: '#F6F6F6',
    colorDisabled: '#ACB0B5',
  },
  checked: {
    backgroundColor: '#794CDE',
    color: '#F8F5FF',
    disabled: {
      backgroundColor: '#6C7176',
      color: '#ACB0B5',
    },
    focused: {
      outline: '2px solid #48249a',
    },
    hover: {
      backgroundColor: '#9465FF',
      color: '#F8F5FF',
    },
  },
  unchecked: {
    backgroundColor: '#1d2127',
    border: '1px solid #ACB0B5',
    disabled: {
      backgroundColor: '#1d2127',
      border: '1px solid #7F858B',
    },
    focused: {
      outline: '2px solid #48249a',
    },
    hover: {
      border: '1px solid #C4C7CA',
    },
  },
}

const dateInput = {
  input: {
    color: '#F6F6F6',
    border: '1px solid #4F555F',
    bgColor: '#13161A',
    popoverButtonColor: '#DCDEE0',
  },
  popover: {
    backgroundColor: '#13161A',
    border: '1px solid #585168',
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.10)',
    headerColor: '#F8F5FF',
    headerButton: {
      color: '#C4AAFF',
      colorHover: '#C4AAFF',
      bgColorHover: '#363B44',
    },
    tableCell: {
      headerColor: '#F8F5FF',
      color: '#F8F5FF',
      colorHover: '#F8F5FF',
      bgColorHover: '#363B44',
      colorOutsideMonth: '#ACB0B5',
      colorDataSelected: '#F9F9FF',
      bgColorDataSelected: '#794CDE',
    },
  },
}

const phoneInput = {
  labelColor: '#FBFAFF',
  border: '1px solid #4F555F',
  color: '#F6F6F6',
  backgroundColor: '#13161a',
  colorSecondary: '#C4C7CA',
  iconColor: '#ACB0B5',
  dropdownBoxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.10)',

  disabled: {
    labelColor: '#ACB0B5',
    border: '1px solid #6C7176',
    color: '#ACB0B5',
    backgroundColor: '#303438',
    iconColor: '#91969B',
  },

  error: {
    border: '1px solid #FA4949',
    boxShadow: '0px 0px 0px 3px #710C0C',
    color: '#FFE2E2',
    iconColor: '#FF7070',
  },

  focus: {
    border: '1px solid #AC88FF',
    boxShadow: '0px 0px 0px 3px #48249A',
  },

  hover: {
    border: '1px solid #818892',
    itemBgColor: '#363B44',
    prefixButtonBackgroundColor: '#303438',
  },
}

export const darkTheme: Theme = {
  ...commonTheme,
  json,
  colors,
  effects,
  loadingSpinner,
  scrollbar,
  textInput,
  selectInput,
  popup: {
    wrapBgColor: '#13161A',
    border: '1px solid var(--Stroke-primary, #585168)',
    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.10)',
    bgColor: '#13161A',
    color: '#FBFAFF',
    tenantSwitcher: {
      color: '#F6F6F6',
      backgroundColor: '#13161A',
      border: '1px solid #4F555F',
      secondaryColor: '#DCCDFF',
      dropdownIconColor: '#DCDEE0',
      menu: {
        border: '1px solid #4F555F',
        color: '#F6F6F6',
        colorDisabled: '#7F858B',
        bgColor: '#13161A',
        option: {
          colorSelected: '#fff',
          bgColorSelected: '#48249A',
          colorHover: '#13161A',
          bgColorHover: '#f1f3f7',
          borderFocus: '2px solid #794CDE',
        },
      },
    },
  },
  sidebar,
  sidebarPopup,
  signicatLogo,
  topMenu,
  button,
  loginPage,
  transactionDetailsPage,
  transactionListPage,
  table,
  checkbox,
  dateInput,
  phoneInput,
}
